import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { LoginComponent } from './features/components/user-account/login/login.component'
import { ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'
import { AxiosInterceptorService } from './axios-interceptor.service'



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [AxiosInterceptorService],
  bootstrap: [AppComponent]
})
export class AppModule {
  // The constructor is used to initialize the AxiosInterceptorService.
  constructor(private axiosInterceptorService: AxiosInterceptorService) {}
}
