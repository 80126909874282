import { Component } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { UserAccountService } from '../../../../services/user-account.service'
import { TokenService } from '../../../../services/token.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  loginForm: FormGroup
  isLoading = false

  constructor(
    private fb: FormBuilder,
    private userAccountService: UserAccountService,
    private router: Router,
    private tokenService: TokenService
  ) {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    })
  }

  async onSubmit() {
    this.isLoading = true
    const credentials = this.loginForm.value
    try {
      const response = await this.userAccountService.login(credentials)
      this.tokenService.storeToken(response.data.token)
      await this.router.navigate(['/home'])
    } catch {
    } finally {
      this.isLoading = false
    }
  }
}
