import { Injectable} from '@angular/core'
import axios, { AxiosResponse } from 'axios'
import { environment } from '../../environments/environment'
import { ChangePasswordRequest } from '../models/user-details.model'


@Injectable({
  providedIn: 'root'
})
export class UserAccountService {
  constructor() {
  }

  login(credentials: { username: string; password: string }): Promise<AxiosResponse> {
    try {
      return axios.post(`${ environment.awsEndpointUrl.baseURL }/account-services/user/login`, credentials)
    } catch (error) {
      throw error
    }
  }

  updateUserPassword(input: ChangePasswordRequest): Promise<AxiosResponse> {
    try {
      return axios.put(`${ environment.awsEndpointUrl.baseURL }/account-services/user/${ input.username }/password`, input)
    } catch (error) {
      throw error
    }
  }

}
