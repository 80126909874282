import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { LoginComponent } from './features/components/user-account/login/login.component'
import { AuthGuard ,PreventLoginForAuthenticatedUserGuard} from './auth.guard'

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [PreventLoginForAuthenticatedUserGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./features/features.module').then(m => m.FeaturesModule),
    canActivate: [AuthGuard]
  },
  { path: '**', redirectTo: '/login' } , // Redirect any unknown paths to /login TODO: create 404 page and redirect to it instead of login
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
