import { Injectable } from '@angular/core'
import { jwtDecode } from 'jwt-decode'

export interface TokenContentDataModel {
  username: string
  iat: number
  exp: number
}

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  decodeToken(): TokenContentDataModel | undefined {
    const token = localStorage.getItem('token')
    if (!token) {
      return
    }
    try {
      return jwtDecode(token)
    } catch (err) {
      return
    }
  }

  getToken(): string | null {
    return localStorage.getItem('token')
  }

  storeToken(token: string): boolean {
    try {
      localStorage.setItem('token', token)
      return true
    } catch (err) {
      return false
    }
  }

  removeToken(): boolean {
    try {
      localStorage.removeItem('token')
      return true
    } catch (err) {
      return false
    }
  }

  isTokenExpired(): boolean {
    const tokenExpiration = this.decodeToken()?.exp
    if (!tokenExpiration) {
      return true
    }
    const nowInSeconds = Math.floor(Date.now() / 1000)
    return tokenExpiration < nowInSeconds
  }
}
