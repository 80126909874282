<section class="h-100 gradient-form" style="background-color: #eee;">
  <div class="container py-5 h-100 ">
    <div class="row d-flex justify-content-center align-items-center h-100 ">
      <div class="col-xl-10 ">
        <div class="card rounded-3 text-black box-shadow">
          <div class="row g-0">
            <div class="col-lg-6">
              <div class="card-body p-md-5 mx-md-4">
                <div class="text-center mt-1 mb-3 pb-1">
                  <i class="fa-solid fa-user fa-6x mb-4" style="color: #04215d; width: 185px "></i>
                  <p>Please login to your account</p>
                </div>
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                  <div class="form-outline mb-3">
                    <label class="form-label" for="form2Example11">Username</label>
                    <input type="email" id="form2Example11" class="form-control"
                           placeholder="Username" formControlName="username"/>
                  </div>
                  <div class="form-outline mb-3">
                    <label class="form-label" for="form2Example22">Password</label>
                    <input type="password" id="form2Example22" class="form-control" placeholder="Password"
                           formControlName="password"/>
                  </div>
                  <div class="text-center pt-5 mb-5 pb-1">
                    <button class="btn btn-primary btn-block fa-lg mb-3 w-100 login-custom-button-color" type="submit"
                            [disabled]="loginForm.invalid || isLoading"> <!-- Disable button when loading -->
                      Login
                      <span *ngIf="isLoading">Loading...</span> <!-- Loading indicator -->
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-lg-6 d-flex align-items-center gradient-custom-2">
              <div class="text-white px-3 py-4 p-md-5 mx-md-4">
                <h1 class="mb-3">Sign in to Continue</h1>
                <h5 class=" mb-0">Transcoding Orchestrator</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
