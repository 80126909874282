import axios, { AxiosError } from 'axios'
import { environment } from '../environments/environment'
import { Router } from '@angular/router'
import { Injectable } from '@angular/core'
import { TokenService } from './services/token.service'

@Injectable()
export class AxiosInterceptorService {
  private readonly baseURL = environment.awsEndpointUrl.baseURL
  private excludedEndpoints: string[] = ['/account-services/user/login'] // Add endpoints that do not require a token here
  private AuthorizationFailedError = 401
  private AccessForbiddenError = 403

  constructor(
    private router: Router,
    private tokenService: TokenService
  ) {
    this.setupInterceptor()
  }

  private setupInterceptor(): void {
    axios.interceptors.request.use(
      (config) => {
        // Check if the request is not for an excluded endpoint
        const requiresToken = !this.excludedEndpoints.some((endpoint) =>
          config.url?.startsWith(`${this.baseURL}${endpoint}`)
        )

        if (requiresToken) {
          if (this.tokenService.isTokenExpired()) {
            this.handleUnauthorizedResponse()
            return Promise.reject()
          }
          const token: string | null = this.tokenService.getToken()
          if (token) {
            config.headers['Authorization'] = token
          }
        }
        return config
      },
      (error: AxiosError) => {
        return error
      }
    )

    axios.interceptors.response.use(
      (response) => {
        return response
      },
      (error: AxiosError) => {
        // We must redirect only in case of authorization error
        if (
          [this.AuthorizationFailedError, this.AccessForbiddenError].includes(
            error!.response!.status
          )
        ) {
          this.handleUnauthorizedResponse()
        }
        // Handling specific HTTP codes: TBD
        return Promise.reject(error)
      }
    )
  }

  private handleUnauthorizedResponse() {
    this.tokenService.removeToken()
    this.router.navigate(['/login'])
  }
}
